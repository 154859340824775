import { BarcodeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import React from "react";
import { pages } from "./breadcrumbs";
import { SpinnerInline } from "../spinners";

export function BreadcrumbsDeveloper() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                {
                    title: (
                        <>
                            <span>Developer area</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesRuns() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                {
                    title: (
                        <>
                            <span>Runs</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperDataImports() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Data Imports</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesRunsRun(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                pages["developer_area_services_runs"],
                {
                    title: (
                        <>
                            <span>{props.loading ? <SpinnerInline /> : <>Run #{props.id} from {props.time}</>}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesProducts() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                {
                    title: (
                        <>
                            <span>Product service</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesAuth() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                {
                    title: (
                        <>
                            <span>Auth service</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesLogger() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                {
                    title: (
                        <>
                            <span>Logger service</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesNotifcations() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                {
                    title: (
                        <>
                            <span>Notification service</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesEcosystem() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                {
                    title: (
                        <>
                            <span>Ecosystem service</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperServicesBizzkitProductFeed() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_services"],
                {
                    title: (
                        <>
                            <span>Bizzkit product feed</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperSlim4() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_integrations"],
                {
                    title: (
                        <>
                            <span>Slim4</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperMoreFlo() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_integrations"],
                {
                    title: (
                        <>
                            <span>MoreFlo</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperProducts() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <BarcodeOutlined />
                            <span>Products</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsDeveloperReplicators() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_products"],
                {
                    title: (
                        <>
                            <span>Replicators</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsDeveloperReplicatorsV1() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_products"],
                pages["developer_area_products_replicators"],
                {
                    title: (
                        <>
                            <span>Replicators V1</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperProductsFeeds() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_products"],
                {
                    title: (
                        <>
                            <span>Feeds</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperProductsFeedsV1() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_products"],
                pages["developer_area_products_feeds"],
                {
                    title: (
                        <>
                            <span>Feeds V1</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperProductsFeedsV2() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_products"],
                pages["developer_area_products_feeds"],
                {
                    title: (
                        <>
                            <span>Feeds V2</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperProductsFeedsF2Logs() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_products"],
                pages["developer_area_products_feeds"],
                pages["developer_area_products_feeds_v2"],
                {
                    title: (
                        <>
                            <span>Logs</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperSentry() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Sentry</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperSentryIssues() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_sentry"],
                {
                    title: (
                        <>
                            <span>Issues</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperCosts() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Costs</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperHealthMonitor() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Health Monitor</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperHealthMonitorTablesizes() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Table sizes</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperHealthMonitorIndexes() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Indexes</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperCostsAzure() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_costs"],
                {
                    title: (
                        <>
                            <span>Azure</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsDeveloperIntegrations() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Integrations</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperIntegrationsStatuses() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_integrations"],
                {
                    title: (
                        <>
                            <span>Statuses</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperIntegrationsAPIKeys() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_integrations"],
                {
                    title: (
                        <>
                            <span>API Keys</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperAccessTokens() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Access Tokens</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperAccessTokenAdd() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_access_tokens"],
                {
                    title: (
                        <>
                            <span>Add</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsDeveloperEndpoints() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                {
                    title: (
                        <>
                            <span>Endpoints</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsDeveloperEndpointsEndpoint(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["developer_area"],
                pages["developer_area_endpoints"],
                {
                    title: (
                        <>
                            <span>{props.id}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}