import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Form, Input } from 'antd';
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { ErrorRow } from "../../components/errorBlock";
import { MonitorOutlined, } from "@ant-design/icons";
import { GetUserLink } from "../../components/getPageLinks";
import { GetTagFromValue } from "../../components/formattingValues";
import { BreadcrumbsCampaignsLogs } from "../../components/breadcrumbs/breadcrumbsCampaigns";
import { DateAndTime, GetDefaultAfter, GetDefaultBefore, TimeFromSeconds } from "../../components/dateAndTime";
import { GetEventLevelsSelect } from "../../components/getEventsData";
import { GetUserSelect } from "../../components/getUsers";
import { Helmet } from "react-helmet";

const columns = [
    {
        title: 'Time',
        dataIndex: 'Time',
        key: 'CreatedAt',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.Timing.CreatedAt.seconds} /></>
        ),
    },
    {
        title: 'CampaignID',
        dataIndex: 'CampaignID',
        key: 'CampaignID',
        render: (_, record) => (
            <>{record.CampaignID !== "" ? <><a
                href={`/campaigns/v2/${record.CampaignID}`}>{record.CampaignID}</a></> : record.CampaignID}</>
        ),
    },
    {
        title: 'ProductID',
        dataIndex: 'ProductID',
        key: 'ProductID',
        render: (_, record) => (
            <>{record.ProductID !== "" ? <><a
                href={`/products/${record.ProductID}`}>{record.ProductID}</a></> : record.ProductID}</>
        ),
    },
    {
        title: 'Level',
        dataIndex: 'LogLevelName',
        key: 'LogLevelName',
        render: (_, record) => (
            <>{GetTagFromValue(record.LogLevel)}</>
        ),
    },
    {
        title: 'Text',
        dataIndex: 'Text',
        key: 'Text',
    },
    {
        title: 'Author',
        dataIndex: 'Author',
        key: 'Author',
        render: (_, record) => (
            <>{record.AuthorName === "" ? <><a href={GetUserLink(record.Author)}>{record.Author}</a></> :
                <a href={GetUserLink(record.Author)}>{record.AuthorName}</a>}</>
        ),
    },
];

export const CampaignLogsV2 = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [level, setLevel] = useState(0);
    const [authorUser, setAuthorUser] = useState("all");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [form] = Form.useForm();
    const skuValue = Form.useWatch('sku', form);
    const cidValue = Form.useWatch('cid', form);

    const [before, setBefore] = useState(GetDefaultBefore());
    const [after, setAfter] = useState(GetDefaultAfter());

    const fetchData = () => {
        let sku = "all"
        if (skuValue !== undefined && skuValue.length > 1) {
            sku = skuValue
        }

        let cid = "all"
        if (cidValue !== undefined && cidValue.length > 1) {
            cid = cidValue
        }

        GetRequestInternalAPI(
            `/api/v1/products/campaigns/v2/get_logs?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&cid=${cid}&sku=${sku}&author=${authorUser}&level=${level}&before=${before}&after=${after}`,
            setLoading,
            setErr,
            null
        ).then(
            (result = { Total: 0, Records: [] }) => {
                setData(result.Records);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: result.Total,
                    },
                });
            }
        )
    };

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [tableParams.pagination.pageSize, tableParams.pagination.current]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    return (
        <>
            <Helmet>
                <title>Campaign actions log || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsCampaignsLogs />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Form form={form} autoComplete="off">
                    <ErrorRow err={err} prefix={"Logs"} />
                    <Row>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Dates" name="Dates"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Before and after',
                                    },
                                ]}>
                                <DateAndTime setBefore={setBefore} setAfter={setAfter} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item name="sku" label="SKU">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item name="cid" label="Campaign ID">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Form.Item
                                name={`Level`}
                                label={`Level`}
                            >
                                <GetEventLevelsSelect onChange={setLevel} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Form.Item
                                name={`User`}
                                label={`User`}
                            >
                                <GetUserSelect grants={props.grants} setUser={setAuthorUser} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Button type="primary" target={"_blank"}
                                rel={"noreferrer"}
                                onClick={fetchData}
                            >
                                Get logs <MonitorOutlined />
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: "1em" }}>
                        <Col xs={24} xl={24}>
                            <Table
                                columns={columns}
                                rowKey={(record) => record.product}
                                dataSource={data}
                                pagination={tableParams.pagination}
                                loading={loading}
                                size={"small"}
                                onChange={handleTableChange}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};
