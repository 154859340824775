import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Alert, Col, Descriptions, Row, Table, Typography } from "antd";
import JSONPretty from "react-json-pretty";
import { BreadcrumbsDeveloperServicesRunsRun } from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import { GetDefaultAfter, GetDefaultBefore, TimeFromSeconds } from "../../../../components/dateAndTime";
import { EventLevelsMap, GetEventSourcesTag } from "../../../../components/getEventsData";
import { IsCrititcal, IsDanger, IsError, IsWarning } from "../../../../components/checkingValues";
import { GetRequest, GetRequestInternalAPI } from "../../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../../components/backend/backend";
import { NotificationError } from "../../../../components/notifications";
import { ErrorRow } from "../../../../components/errorBlock";
import { GetUserLink } from "../../../../components/getPageLinks";
const JSONPrettyMon = require('react-json-pretty/dist/monikai');

const columns = [
    {
        title: 'Time',
        dataIndex: 'Time',
        key: 'Time',
        render: (_, record) => (
            <TimeFromSeconds seconds={record.Timing.CreatedAt.seconds} />
        ),
    },
    {
        title: 'Source',
        dataIndex: 'logSource',
        key: 'logSource',
        render: (_, record) => (
            <GetEventSourcesTag source={record.LogSource} />
        ),
    },
    {
        title: 'Level',
        dataIndex: 'logLevel',
        key: 'logLevel',
        render: (_, record) => (
            record.LogLevel !== undefined && record.LogLevel !== null ? EventLevelsMap[record.LogLevel].tag : <>???</>
        ),
    },
    {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        render: (_, record) => (
            IsWarning(record.LogLevel)
                ? <Alert message={record.Text} type="warning" />
                : IsError(record.LogLevel) || IsDanger(record.LogLevel) || IsCrititcal(record.LogLevel)
                    ? <Alert message={record.Text} type="error" />
                    : <>{record.Text}</>
        ),
    },
    {
        title: 'Author',
        dataIndex: 'LogAuthor',
        key: 'LogAuthor',
        render: (_, record) => (
            record.Author !== "" ? <a href={GetUserLink(record.Author)}>{record.AuthorName ? record.AuthorName : record.Author}</a> : <></>
        ),
    },
];

export const Run = () => {
    const { id } = useParams();
    // eslint-disable-next-line
    const [err, setErr] = useState("");
    const [data, setData] = useState({ StartedAt: {} });
    const [loading, setLoading] = useState(false);
    const [logsErr, setLogsErr] = useState("");
    const [logsData, setLogsData] = useState();
    const [logsLoading, setLogsLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const [before] = useState(GetDefaultBefore());
    const [after] = useState(GetDefaultAfter());

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchLogsData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const fetchData = () => {
        setLoading(true);
        GetRequestInternalAPI(`/api/v1/dev/services/runs/get?run_id=${id}`, setLoading, setErr, null)
            .then((result = {}) => {
                if (result.record) {
                    setData(result.record);
                }
            });
    };

    const fetchLogsData = () => {
        setLogsLoading(true);
        GetRequest(getBackendLink() + `/api/v1/logs/get_list?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&before=${before}&after=${after}&run_id=${id}`)
            .then((result) => {
                if (!result.success) {
                    actionOnError(result.error);
                    setLogsErr(result.error);
                } else {
                    setLogsErr("");
                    setLogsData(result.result.Records);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.result.Total,
                        },
                    });
                }
                setLogsLoading(false);
            })
            .catch((error) => {
                setLogsLoading(false);
                setLogsErr(error.message);
                NotificationError('Error', error.message);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setLogsData([]);
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Application run #${id}`} || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperServicesRunsRun loading={loading} id={id}
                            time={<TimeFromSeconds seconds={data.StartedAt.seconds} />} />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Descriptions bordered>
                    <Descriptions.Item label="Run ID">
                        <Typography.Text copyable={{ text: data.RunID }}>
                            {data.RunID}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Started at">
                        {data.StartedAt ? <TimeFromSeconds seconds={data.StartedAt.seconds} /> : <></>}
                    </Descriptions.Item>
                    <Descriptions.Item label="Done">
                        <Typography.Text>
                            {data.IsStopped ? `${data.IsStopped} (${data.Result})` : ""}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Done at">
                        {data.StoppedAt ? <TimeFromSeconds seconds={data.StoppedAt.seconds} /> : <></>}
                    </Descriptions.Item>
                    <Descriptions.Item label="Application">
                        {<GetEventSourcesTag source={data.SourceID} />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                        {data.SourceType}
                    </Descriptions.Item>
                    <Descriptions.Item label="Metadata">
                        <JSONPretty id="json-pretty" data={data.Metadata} theme={JSONPrettyMon} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Initial message">
                        {data.Text}
                    </Descriptions.Item>
                </Descriptions>
            </div>

            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        {logsErr ? (
                            <ErrorRow err={logsErr} prefix={"Runs"} />
                        ) : (
                            <Table
                                columns={columns}
                                rowKey={(record) => record.product}
                                dataSource={logsData}
                                pagination={tableParams.pagination}
                                loading={logsLoading}
                                size={"small"}
                                onChange={handleTableChange}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};