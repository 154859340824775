import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { Table, Tag } from 'antd';
import { Avatar } from 'antd';
import { GetRequest } from "../../components/backend/getRequest"
import { ErrorBlock } from "../../components/errorBlock"
import { Space } from 'antd';
import { actionOnError, errorTranslation, getBackendLink } from "../../components/backend/backend"
import { NotificationError } from "../../components/notifications";
import { BreadcrumbsAdminUsers } from "../../components/breadcrumbs/breadcrumbsAdmin";
import { CanEditUsers } from "../../components/backend/backendRoles";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { Helmet } from "react-helmet";
import { FormatShortUUID } from '../../components/formattingValues';


function AvatarSmall(avatar_link) {
    if (avatar_link === "") {
        return (
            <>
                <Avatar shape="square"></Avatar>
            </>
        )
    }
    return (
        <>
            <Avatar shape="square" src={avatar_link}></Avatar>
        </>
    )
}

export const AdminUsersList = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/users/admin/get-list?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
            key: 'ID',
            render: (_, record) => (
                <><a href={'/profile/' + record.ID} target='_blank' rel='noreferrer'>{FormatShortUUID(record.ID)}</a></>
            ),
        },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            key: 'avatar',
            render: (_, record) => (
                <><a href={'/profile/' + record.ID} target='_blank' rel='noreferrer'>{AvatarSmall(record.AvatarLink)}</a></>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <>{record.FirstName} {record.LastName}</>
            ),
            sorter: (a, b) => a.FirstName + a.LastName - b.FirstName + b.LastName,
        },
        {
            title: 'Corp Email',
            dataIndex: 'BHEmail',
            key: 'BHEmail',
            render: (_, record) => (
                !record.BHEmail
                    ?
                    <></>
                    :
                    <Typography.Text
                        copyable={{ text: record.BHEmail }}
                        style={{ margin: 0 }}>
                        {record.BHEmail}
                    </Typography.Text>

            ),
        },
        {
            title: 'Can login',
            dataIndex: 'LoginAllowed',
            key: 'LoginAllowed',
            render: (_, record) => (
                <>{record.LoginAllowed === true ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
            ),
        },
        {
            title: 'Last login',
            dataIndex: 'Last_login',
            key: 'Last_login',
            render: (_, record) => (
                <>{record.LastLogin.seconds > 0 ?
                    <TimeFromSeconds seconds={record.LastLogin.seconds} />
                    : <></>}</>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a href={'/profile/' + record.ID} target='_blank' rel='noreferrer'>View</a>
                    {CanEditUsers(props.token.grants) === true ?
                        <a href={'/admin/users/edit/' + record.ID} target='_blank' rel='noreferrer'>Edit</a> : <></>}
                </Space>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>User list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminUsers />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    {err !== "" ? <Col xs={24} xl={24}><ErrorBlock error={errorTranslation(err)} /></Col> : <></>}
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.ID}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
