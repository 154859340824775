import { DatePicker } from 'antd';
import React from "react";
import dayjs from "dayjs";
import Moment from "react-moment";

const { MonthPicker } = DatePicker;


export const DateAndTime = (props) => {
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DD';

    const before = GetDefaultBefore();
    const after = GetDefaultAfter();

    const setRange = (value) => {
        let start = value[0];
        let end = value[1];
        if (props.appendDayToEnd === true) {
            end = end.add(1, 'day');
        }

        const startYear = start.format('YYYY');
        const startMonth = start.format('MM');
        const startDay = start.format('DD');
        const startText = `${startYear}-${startMonth}-${startDay}`;

        const endYear = end.format('YYYY');
        const endMonth = end.format('MM');
        const endDay = end.format('DD');
        const endText = `${endYear}-${endMonth}-${endDay}`;

        props.setAfter(startText);
        props.setBefore(endText);
    }

    // const setRange = (value) => {
    //     let beforeParsed = dayjs(value[1], dateFormat);

    //     if (props.appendDayToBefore === true){
    //         beforeParsed = beforeParsed.add(1, 'day');
    //     }

    //     const beforeYear = beforeParsed.year();
    //     const beforeMonth = beforeParsed.month() + 1;
    //     const beforeDay = beforeParsed.date();
    //     const beforeText = [beforeYear, beforeMonth.toString().padStart(2, '0'), beforeDay.toString().padStart(2, '0')].join('-');
    //     props.setBefore(beforeText)



    //     const afterParsed = dayjs(value[0], dateFormat);
    //     const afterYear = afterParsed.year();
    //     const afterMonth = afterParsed.month() + 1;
    //     const afterDay = afterParsed.date();
    //     const afterText = [afterYear, afterMonth.toString().padStart(2, '0'), afterDay.toString().padStart(2, '0')].join('-');

    //     props.setAfter(afterText)
    // }

    let defValue = null
    if (props.noDefaults === true) {
        defValue = null
    } else {
        defValue = [dayjs(after, dateFormat), dayjs(before, dateFormat)]
    }

    let disabledDate = null
    if (props.disableBeforeToday === true) {
        disabledDate = DisabledBeforeToday
    }

    if (props.disableBeforeYesterday === true) {
        disabledDate = DisabledBeforeYesterday
    }

    return (
        <>
            <RangePicker
                onChange={(value) => {
                    setRange(value)
                }}
                defaultValue={defValue}
                disabledDate={disabledDate}
                format={dateFormat}
            />
        </>
    )
}

export function GetDefaultBefore() {
    const beforeParsed = new Date(Date.now());
    beforeParsed.setUTCMonth(beforeParsed.getUTCMonth() + 1)
    const beforeYear = beforeParsed.toLocaleString('default', { year: 'numeric' });
    const beforeMonth = beforeParsed.toLocaleString('default', { month: '2-digit', });
    const beforeDay = beforeParsed.toLocaleString('default', { day: '2-digit' });
    const beforeText = [beforeYear, beforeMonth, beforeDay].join('-');

    return beforeText
}

export function GetDefaultAfter() {
    const afterParsed = new Date(Date.now() - (86400000));
    afterParsed.setUTCMonth(afterParsed.getUTCMonth() - 1)
    const afterYear = afterParsed.toLocaleString('default', { year: 'numeric' });
    const afterMonth = afterParsed.toLocaleString('default', { month: '2-digit', });
    const afterDay = afterParsed.toLocaleString('default', { day: '2-digit' });
    const afterText = [afterYear, afterMonth, afterDay].join('-');

    return afterText
}

export function GetCurrentYear() {
    return new Date().getFullYear();
}

export function GetCurrentMonth() {
    return new Date().getMonth() + 1;
}

export const TimeFromSecondsSafe = (props) => {
    if (props.time === null || props.time === undefined) {
        return <></>
    }

    return <TimeFromSeconds seconds={props.time.seconds} />
}

export const TimeFromSeconds = (props) => {
    if (props.seconds === null || props.seconds === undefined || props.seconds <= 0 || isNaN(props.seconds)) {
        return <></>
    }

    return <Moment format="YYYY.MM.DD HH:mm:ss" unix>{props.seconds}</Moment>
}

export const TimeFromString = (props) => {
    if (props.time === null || props.time === undefined || !isNaN(props.time) || props.time === "") {
        return <></>
    }

    return <Moment format="YYYY.MM.DD HH:mm:ss" date={props.time} />
}

export const DisabledBeforeToday = (current) => {
    // Can not select days before today.
    return current && current < dayjs().endOf('day');
};

export const DisabledBeforeYesterday = (current) => {
    // Can not select days before yesterday.
    return current && current < dayjs().subtract(1, 'day').endOf('day');
};

export const GetYearAndMonth = (props) => {
    const changeMonthIn = (date, _) => {
        if (date) {
            const year = date.year();
            const month = date.month() + 1; // month() returns 0-11, so add 1

            if (props.setYear !== null && props.setYear !== undefined) {
                props.setYear(year)
            }

            if (props.setMonth !== null && props.setMonth !== undefined) {
                props.setMonth(month)
            }
        }
    }

    return <MonthPicker onChange={changeMonthIn} placeholder="Select month" />
}