import { useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import React, { useEffect } from "react";
import { Table, Tag } from "antd";
import { ErrorRow } from "../../components/errorBlock";
import { TimeFromSeconds } from "../../components/dateAndTime";

export const SecurityFailedLoginAttempts = () => {
    const [loadingFailedAttemptsData, setLoadingFailedAttemptsData] = useState(false);
    const [failedAttemptsData, setFailedAttemptsData] = useState([]);
    const [failedAttemptsErr, setFailedAttemptsErr] = useState("");
    const [failedAttemptsTableParams, setFailedAttemptsTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleFailedAttemptsTableChange = (pagination, filters, sorter) => {
        setFailedAttemptsTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== failedAttemptsTableParams.pagination?.pageSize) {
            setFailedAttemptsData([]);
        }
    };

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/security/self/failed_attempts?page=${failedAttemptsTableParams.pagination.current}&size=${failedAttemptsTableParams.pagination.pageSize}`,
            setLoadingFailedAttemptsData,
            setFailedAttemptsErr,
            null
        ).then(
            (result) => {
                setFailedAttemptsData(result.Records)
                setFailedAttemptsTableParams({
                    ...failedAttemptsTableParams,
                    pagination: {
                        ...failedAttemptsTableParams.pagination,
                        total: result.Total,
                    },
                });
            }
        )
        // eslint-disable-next-line
    }, [failedAttemptsTableParams.pagination.pageSize, failedAttemptsTableParams.pagination.current]);

    const columnsFailedAttempts = [
        {
            title: 'IP',
            dataIndex: 'ipAddr',
            key: 'ipAddr',
        },
        {
            title: 'Time',
            dataIndex: 'Time',
            key: 'Time',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.createdAt.seconds} /></>
            ),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Sign Method',
            dataIndex: 'method',
            key: 'method',
            render: (_, record) => (
                <>{record.method === "internal" ? <Tag color={'volcano'}>Login & password</Tag> :
                    <Tag color={'lime'}>Microsoft Auth</Tag>}</>
            ),
        },
    ];

    if (failedAttemptsErr !== "") {
        return <ErrorRow prefix={"Failed login attempts"} err={failedAttemptsErr} />
    }

    return (
        <>
            <Table
                columns={columnsFailedAttempts}
                rowKey={(record) => record.ID}
                dataSource={failedAttemptsData}
                pagination={failedAttemptsTableParams.pagination}
                loading={loadingFailedAttemptsData}
                size={"small"}
                onChange={handleFailedAttemptsTableChange}
            />
        </>
    )
}