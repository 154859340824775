import React from 'react';
import { Button, Col, Popconfirm, Row, Space, Table, DatePicker, Tag, message } from "antd";
import { ErrorRow } from "../../components/errorBlock";
import { useEffect, useState } from 'react';
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    BreadcrumbsCampaignsCreate
} from "../../components/breadcrumbs/breadcrumbsCampaigns";
import { Helmet } from "react-helmet";
import { GetCampaignLink } from "../../components/getPageLinks";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { GetRequestInternalAPI } from '../../components/backend/getRequest';
import { PostRequest } from '../../components/backend/postRequest';
import { SpinnerInline } from '../../components/spinners';
import { GetCountries } from '../../components/backend/getters';
import moment from 'moment';
import { actionOnError, getBackendLink } from '../../components/backend/backend';
import { Tooltip } from 'antd';

export const CampaignsClickUp = () => {
    const [clickUpGetErr, setClickUpGetErr] = useState('');
    const [clickUpData, setClickUpData] = useState([]);
    const [clickUpLoading, setClickUpLoading] = useState(false);
    const [clickUpTableParams, setClickUpTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [dateFrom, setDateFrom] = useState();

    const fetchClickUp = () => {
        const after = dateFrom ? Math.floor(new Date(dateFrom).getTime()) : null;
        const url = `/api/v1/integrations/click_up/campaigns?page=${clickUpTableParams.pagination.current}${after ? `&after=${after}` : ''}`;

        GetRequestInternalAPI(url,
            setClickUpLoading,
            setClickUpGetErr,
            null
        )
            .then(
                (result) => {
                    if (result.records !== undefined) {
                        setClickUpData(result.records)
                        setClickUpTableParams({
                            ...clickUpTableParams,
                            pagination: {
                                ...clickUpTableParams.pagination,
                                total: result.total,
                            },
                        });
                    }
                }
            )
    };

    useEffect(() => {
        fetchClickUp();
        // eslint-disable-next-line
    }, [JSON.stringify(clickUpTableParams)]);

    const [clickUpBrandsGetErr, setClickUpBrandsGetErr] = useState('');
    const [clickUpBrandsData, setClickUpBrandsData] = useState();
    const [clickUpBrandsLoading, setClickUpBrandsLoading] = useState(true);

    const fetchClickUpBrands = () => {
        const url = `/api/v1/integrations/click_up/brands`;

        GetRequestInternalAPI(url,
            setClickUpBrandsLoading,
            setClickUpBrandsGetErr,
            null
        )
            .then(
                (result) => {
                    if (result.records !== undefined) {
                        setClickUpBrandsData(result.records)
                    }
                }
            )
    };

    useEffect(() => {
        fetchClickUpBrands();
        // eslint-disable-next-line
    }, []);

    const handleClickUpTableChange = (pagination, filters, sorter) => {
        setClickUpTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== clickUpTableParams.pagination?.pageSize) {
            setClickUpData([]);
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadingRows, setLoadingRows] = useState([]); // State to track rows with spinner

    const start = () => {
        setLoadingRows(selectedRowKeys);
        setSelectedRowKeys([]);

        const selectedRecords = clickUpData.filter(record => selectedRowKeys.includes(record.task_id));
        selectedRecords.forEach(record => {
            if (record.brand !== undefined) {
                const brand = clickUpBrandsData?.find(b => b.brand_clickup_list_id === record.brand);
                const markets = record.markets && record.markets.length > 0 ? record.markets : dataCountries.map(country => country.LandISO);
                const marketNames = markets.map(market => {
                    const country = dataCountries.find(country => country.LandISO === market);
                    return country ? country.ID : market;
                })

                if (brand && marketNames) {
                    marketNames.forEach((market) => {
                        if (market === 255 || market === 251 || market === 245 || market === 252) {
                            return;
                        }

                        PostRequest(getBackendLink() + `/api/v1/products/campaigns/v2/add`,
                            {
                                "brand_id": parseInt(brand.brand_id, 10),
                                "market": market,
                                "start_date": moment(parseInt(record.start_date, 10)).format("YYYY-MM-DD"),
                                "due_date": moment(parseInt(record.due_date, 10)).format("YYYY-MM-DD"),
                                "discount_percent": record.discount_percent,
                                "skus_include": record.sku_include,
                                "skus_exclude": record.sku_exclude,
                                "click_up_task_id": record.task_id,
                            }
                        )
                            .then(
                                (result) => {
                                    if (result.success !== true) {
                                        const errorMessage = result.error || "Unknown error";
                                        actionOnError(errorMessage);

                                        setClickUpData(prevData => prevData.map(item => {
                                            if (item.task_id === record.task_id) {
                                                return {
                                                    ...item,
                                                    campaign_ids: [...(item.campaign_ids || []), { error: errorMessage }]
                                                };
                                            }
                                            return item;
                                        }));

                                        message.error(`Error creating campaign for task ${record.task_id}: ${errorMessage}`);
                                    } else {
                                        const campaignResult = result.result;
                                        if (campaignResult.CampaignID) {
                                            setClickUpData(prevData => prevData.map(item => {
                                                if (item.task_id === record.task_id) {
                                                    return {
                                                        ...item,
                                                        campaign_ids: [...(item.campaign_ids || []), campaignResult.CampaignID]
                                                    };
                                                }
                                                return item;
                                            }));

                                            message.success(`Campaign created for task ${record.task_id}`);
                                        } else if (campaignResult.Error) {
                                            setClickUpData(prevData => prevData.map(item => {
                                                if (item.task_id === record.task_id) {
                                                    return {
                                                        ...item,
                                                        campaign_ids: [...(item.campaign_ids || []), { error: campaignResult.Error }]
                                                    };
                                                }
                                                return item;
                                            }));

                                            message.error(`Error creating campaign for task ${record.task_id}: ${campaignResult.Error}`);
                                        }
                                    }
                                },
                                (error) => {
                                    setClickUpData(prevData => prevData.map(item => {
                                        if (item.task_id === record.task_id) {
                                            return {
                                                ...item,
                                                campaign_ids: [...(item.campaign_ids || []), { error: error.message }]
                                            };
                                        }
                                        return item;
                                    }));

                                    message.error(`Error creating campaign for task ${record.task_id}: ${error.message}`);
                                }
                            )
                            .finally(() => {
                                setLoadingRows(prevLoadingRows => prevLoadingRows.filter(id => id !== record.task_id));
                            });
                    });
                }
            }
        });
    };

    const [errCountries, setErrCountries] = useState('');
    const [dataCountries, setDataCountries] = useState([]);
    const [loadingCountries, setLoadingCountries] = useState(true);

    useEffect(() => {
        GetCountries(
            setLoadingCountries,
            setErrCountries,
            null,
        ).then(
            (result) => {
                let counts = []
                result.Records.map((x) => (
                    counts.push({
                        ID: x.ID,
                        LandISO: x.LandISO,
                        Name: `${x.LandISO} (${x.Name})`,
                    })
                ))

                setDataCountries(counts);
            }
        )
        // eslint-disable-next-line
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    // Add this function to render the SKU info
    const renderSkuInfo = (record) => {
        if ((record.sku_include && record.sku_include.length > 0) || (record.sku_exclude && record.sku_exclude.length > 0)) {
            return (
                <Tooltip title={`Include SKUs: ${record.sku_include || 'None'}, Exclude SKUs: ${record.sku_exclude || 'None'}`}>
                    <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
            );
        }
        return null;
    };

    const columns = (loadingRows) => [
        {
            title: 'Task',
            dataIndex: 'Task',
            key: 'Task',
            render: (_, record) => (
                <>
                    <a href={record.task_url} target={"_blank"} rel={"noreferrer"}>{record.task_id}</a>
                    {renderSkuInfo(record)}
                </>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'task_name',
            key: 'task_name',
        },
        {
            title: 'Status',
            dataIndex: 'status_name',
            key: 'status_name',
        },
        {
            title: 'Created by',
            dataIndex: 'creator_name',
            key: 'creator_name',
        },
        {
            title: 'Created at',
            dataIndex: 'creator_name',
            key: 'creator_name',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.date_created / 1000} /></>
            ),
        },
        {
            title: 'From',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.start_date / 1000} /></>
            ),
        },
        {
            title: 'To',
            dataIndex: 'due_date',
            key: 'due_date',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.due_date / 1000} /></>
            ),
        },
        {
            title: 'Discount',
            dataIndex: 'discount_percent',
            key: 'discount_percent',
            render: (text) => {
                if (text === undefined) {
                    return <Tag color="red">No discount</Tag>
                }
                const isInteger = Number.isInteger(parseInt(text, 10));
                return !isInteger ? <Tag color="red">{text + "!"}</Tag> : text;
            },
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            render: (_, record) => {
                if (clickUpBrandsLoading) {
                    return <SpinnerInline />
                } else {
                    if (record.brand !== undefined) {
                        const brand = clickUpBrandsData?.find(b => b.brand_clickup_list_id === record.brand);
                        return brand ? brand.brand_name : <Tag color="red">{record.brand}</Tag>;
                    } else {
                        return <Tag color="red">No brand</Tag>
                    }
                }
            },
        },
        {
            title: 'Markets',
            dataIndex: 'markets',
            key: 'markets',
            render: (_, record) => (
                Array.isArray(record.markets) ? (
                    record.markets.map((id, index) => (
                        <span key={id}>
                            {id}
                            {index < record.markets.length - 1 && ', '}
                        </span>
                    ))
                ) : (
                    <></>
                )
            ),
        },
        {
            title: 'Campaigns',
            dataIndex: 'campaign_ids',
            key: 'campaign_ids',
            render: (_, record) => (
                loadingRows.includes(record.task_id) ? <SpinnerInline /> : (
                    Array.isArray(record.campaign_ids) ? (
                        record.campaign_ids.map((id, index) => (
                            <span key={id}>
                                {id.error ? <Tag color="red">{id.error}</Tag> : GetCampaignLink(id, id)}
                                {index < record.campaign_ids.length - 1 && ', '}
                            </span>
                        ))
                    ) : (
                        GetCampaignLink(record.campaign_ids, record.campaign_ids)
                    )
                )
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>ClickUp campaigns || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsCampaignsCreate />
                    </Col>
                </Row>
            </div>
            <ErrorRow err={clickUpGetErr} prefix={"Campaigns"} />
            <ErrorRow err={clickUpBrandsGetErr} prefix={"Brands"} />
            <ErrorRow err={errCountries} prefix={"Markets"} />
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button href={"/campaigns/v2"} type="primary">
                                Campaign list
                            </Button>
                            <Popconfirm
                                title="Sync selected"
                                description="Are you sure to sync these records?"
                                onConfirm={start}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <Button
                                    disabled={!hasSelected}
                                    type="dashed"
                                    danger
                                >
                                    Import selected
                                </Button>
                            </Popconfirm>
                            <DatePicker onChange={(date, dateString) => setDateFrom(date)} />
                            <Button type="primary" htmlType="submit" onClick={fetchClickUp}>
                                Refresh list
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            dataSource={clickUpData}
                            columns={columns(loadingRows)}
                            loading={clickUpLoading || loadingCountries || clickUpBrandsLoading}
                            pagination={clickUpTableParams.pagination}
                            rowKey={(record) => record.task_id}
                            size={"small"}
                            onChange={handleClickUpTableChange}
                            rowSelection={rowSelection}
                            scroll={{ y: 400 }}
                            sticky
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}