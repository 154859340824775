import React, { useEffect, useState } from "react";
import { Col, Descriptions, Row, Typography } from "antd";
import { ErrorRow } from "../../../../components/errorBlock";
import { TimeFromSeconds } from "../../../../components/dateAndTime";
import {
    GetApplicationRunHref, GetApplicationRunLink,
} from "../../../../components/getPageLinks";
import { GetRequestInternalAPI } from "../../../../components/backend/getRequest";
import {
    BreadcrumbsDeveloperServicesAuth
} from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import JSONPretty from "react-json-pretty";
import { Spinner } from "../../../../components/spinners";
import { CopyableText } from "../../../../components/copyable";
import { Helmet } from "react-helmet";
const JSONPrettyMon = require('react-json-pretty/dist/monikai');

export const AuthService = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const getData = () => {
        GetRequestInternalAPI(
            `/api/v1/dev/services/parameters/auth`,
            setLoading,
            setErr,
            null,
        ).then(
            (result = { parameters: {} }) => {
                if (result.parameters !== null) {
                    setData(result.parameters)
                }
            }
        )
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, []);


    if (err !== "") {
        return (
            <>
                <Helmet>
                    <title>Auth service || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsDeveloperServicesAuth />
                        </Col>
                    </Row>
                </div>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={err} prefix={"Auth service stats"} />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    if (loading) {
        return (
            <>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsDeveloperServicesAuth />
                        </Col>
                    </Row>
                </div>
                <Spinner />
            </>
        )
    }

    return (
        <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperServicesAuth />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Descriptions>
                    <Descriptions.Item label="Run ID">
                        <Typography.Text
                            copyable={{ text: GetApplicationRunHref(data.RunID) }}>
                            {GetApplicationRunLink(data.RunID)}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Started at">{data.ServiceStartedAt !== undefined && data.ServiceStartedAt !== null ? <TimeFromSeconds seconds={data.ServiceStartedAt.seconds} /> : <></>}</Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Typography.Title
                    level={3} style={{ margin: 0 }}>
                    Active settings
                </Typography.Title>
                {data.Settings !== undefined && data.Settings !== null
                    ?
                    <Descriptions>
                        {data.Settings.map((setting) => (
                            <Descriptions.Item label={`${setting.Name}`}><CopyableText text={setting.Value} /></Descriptions.Item>
                        ))}
                    </Descriptions>
                    :
                    <></>
                }
            </div>
            <div className="container-1700">
                {<JSONPretty id="json-pretty" data={data} theme={JSONPrettyMon} />}
            </div>
        </>
    )
}
