import React, { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { ErrorRow } from "../../components/errorBlock";
import { Spinner } from "../../components/spinners";
import { Descriptions, Tag } from "antd";
import { GetUserLink } from "../../components/getPageLinks";
import { TimeFromSeconds } from "../../components/dateAndTime";

export const SessionData = (props = {}) => {
    const [loadingData, setLoadingData] = useState(false);
    const [err, setErr] = useState("");
    const [data, setData] = useState({
        LastActivityAt: {},
        CreatedAt: {},
        EndedAt: {},
    });

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/sessions/admin/get?id=${props.id}`,
            setLoadingData,
            setErr,
            null
        ).then(
            (result = {}) => {
                setData(result)
                if (props.setCrumbsName !== null && props.setCrumbsName !== undefined) {
                    props.setCrumbsName(<>{result.SessionID} ({result.UserName})</>)
                }

            }
        )
        // eslint-disable-next-line
    }, []);

    if (err !== "") {
        return <ErrorRow prefix={"Session data"} err={err} />
    }

    if (loadingData) {
        return <><Spinner /></>
    }

    return (
        <>
            <Descriptions>
                <Descriptions.Item
                    label="ID">{data.SessionID}</Descriptions.Item>
                <Descriptions.Item
                    label="User">{<a
                        href={GetUserLink(data.UserID)}>{data.UserName}</a>}</Descriptions.Item>
                <Descriptions.Item
                    label="IP Address">{data.IPAddr}</Descriptions.Item>
                <Descriptions.Item
                    label="Country">{data.Country}</Descriptions.Item>
                <Descriptions.Item
                    label="Location">{data.Location}</Descriptions.Item>
                <Descriptions.Item
                    label="SignedIn with">{data.SignInMethod}</Descriptions.Item>
                <Descriptions.Item
                    label="Started">
                    <> {data.CreatedAt ? <TimeFromSeconds seconds={data.CreatedAt.seconds} /> : <></>}
                    </>
                </Descriptions.Item>
                <Descriptions.Item label="Ended">
                    {
                        data.IsEnded
                            ?
                            <>
                                <TimeFromSeconds seconds={data.EndedAt.seconds} />
                            </>
                            :
                            <>
                                <Tag>No</Tag>
                            </>
                    }
                </Descriptions.Item>
                <Descriptions.Item
                    label="OS">{data.OS} {data.OSVersion}</Descriptions.Item>
                <Descriptions.Item
                    label="Browser">{data.BrowserName} {data.BrowserVersion}</Descriptions.Item>
                <Descriptions.Item
                    label="Not my device">
                    {
                        data.NotMyDevice === true ? <Tag color={'volcano'}>YES</Tag> :
                            <Tag color={'lime'}>NO</Tag>
                    }
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}