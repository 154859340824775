import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Form, Input, Card, Tag } from 'antd';
import { GetRequestInternalAPI } from "../../../components/backend/getRequest";
import { ErrorRow } from "../../../components/errorBlock";
import { MonitorOutlined } from "@ant-design/icons";
import { DateAndTime, GetDefaultAfter, GetDefaultBefore, TimeFromSecondsSafe } from "../../../components/dateAndTime";
import {
    GetEventSourcesSelect,
} from "../../../components/getEventsData";
import { BreadcrumbsDeveloperDataImports } from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { Helmet } from "react-helmet";
import { FormatShortUUID, GetTagFromBooleanYesNo } from '../../../components/formattingValues';
import { Typography } from "antd";

const { Paragraph } = Typography

const columns = [
    {
        title: 'Import ID',
        dataIndex: 'Started',
        key: 'Started',
        render: (_, record) => (
            <>
                <Paragraph copyable={{ text: record.import_id }}>
                    {FormatShortUUID(record.import_id)}
                </Paragraph>
            </>
            // <>{}</>
        ),
    },
    {
        title: 'Started',
        dataIndex: 'Started',
        key: 'Started',
        render: (_, record) => (
            <><TimeFromSecondsSafe time={record.started_at} /></>
        ),
    },
    {
        title: 'Finished',
        dataIndex: 'Finished',
        key: 'Finished',
        render: (_, record) => (
            <><TimeFromSecondsSafe time={record.finished_at} /></>
        ),
    },
    {
        title: 'Source',
        dataIndex: 'data_source',
        key: 'data_source',
    },
    {
        title: 'Target',
        dataIndex: 'data_target',
        key: 'data_target',
    },
    {
        title: 'Type',
        dataIndex: 'data_type',
        key: 'data_type',
    },
    {
        title: 'Key',
        dataIndex: 'unique_key',
        key: 'unique_key',
    },
    {
        title: 'Worker',
        dataIndex: 'worker',
        key: 'worker',
    },
    {
        title: 'Finished',
        dataIndex: 'is_finished',
        key: 'is_finished',
        render: (_, record) => (
            <>{GetTagFromBooleanYesNo(record.is_finished)}</>
        ),
    },
    {
        title: 'Success',
        dataIndex: 'is_failed',
        key: 'is_failed',
        render: (_, record) => (
            <>{GetTagFromBooleanYesNo(!record.is_failed)}</>
        ),
    },
];

export const DataImportsList = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [worker, setWorker] = useState("");
    const [form] = Form.useForm();
    const importID = Form.useWatch('import_id', form);
    const uniqueKey = Form.useWatch('unique_key', form);

    // eslint-disable-next-line
    const [targetUser, setTargetUser] = useState("");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [before, setBefore] = useState(GetDefaultBefore());
    const [after, setAfter] = useState(GetDefaultAfter());

    const fetchData = () => {
        const localImportID = importID || "";
        const localUniqueKey = uniqueKey || "";

        GetRequestInternalAPI(
            `/api/v1/integrations/dataimports/list?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&import_id=${localImportID}&worker=${worker}&unique_key=${localUniqueKey}&before=${before}&after=${after}`,
            setLoading,
            setErr,
            null,
        )
            .then(
                (result) => {
                    setData(result.records);
                    setTableParams((prevParams) => ({
                        ...prevParams,
                        pagination: {
                            ...prevParams.pagination,
                            total: result.total,
                        },
                    }));
                }
            );
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    return (
        <>
            <Helmet>
                <title>Data Import Logs || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperDataImports />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <ErrorRow err={err} prefix={"Logs"} />
                <Card style={{ marginTop: "1em" }}>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={12}>
                                <Form.Item label="Dates" name="Dates"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select Before and after',
                                        },
                                    ]}>
                                    <DateAndTime setBefore={setBefore} setAfter={setAfter} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={12}>
                                Total: <Tag color={"purple"}>{tableParams.pagination.total}</Tag>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={8}>
                                <Form.Item
                                    name={`Worker`}
                                    label={`Worker`}
                                >
                                    <GetEventSourcesSelect onChange={setWorker} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={24}>
                                <Form.Item name="import_id" label="Import ID">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={24}>
                                <Form.Item name="unique_key" label="Unique key">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} xl={24}>
                            <Button type="primary" target={"_blank"}
                                rel={"noreferrer"}
                                onClick={fetchData}
                            >
                                Get logs <MonitorOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ marginTop: "1em" }}>
                    <Row>
                        <Col xs={24} xl={24}>
                            <Table
                                columns={columns}
                                rowKey={(record) => record.product}
                                dataSource={data}
                                pagination={tableParams.pagination}
                                loading={loading}
                                size={"small"}
                                onChange={handleTableChange}
                                scroll={{ x: 'max-content' }}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
};