import React, { useEffect, useState } from 'react';
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { NotificationError } from "../../components/notifications";
import { Col, Row, Table } from "antd";
import {
    BreadcrumbsAdminUsersFailedLogins
} from "../../components/breadcrumbs/breadcrumbsAdmin";
import { ErrorRow } from "../../components/errorBlock";
import { GetIPCheckerLink, GetUserLink } from "../../components/getPageLinks";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { Helmet } from "react-helmet";

export const FailedLogins = () => {
    const [loadingFailedAttemptsData, setLoadingFailedAttemptsData] = useState(false);
    const [failedAttemptsData, setFailedAttemptsData] = useState([]);
    const [failedAttemptsErr, setFailedAttemptsErr] = useState("");
    const [failedAttemptsTableParams, setFailedAttemptsTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleFailedAttemptsTableChange = (pagination, filters, sorter) => {
        setFailedAttemptsTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== failedAttemptsTableParams.pagination?.pageSize) {
            setFailedAttemptsData([]);
        }
    };


    const fetchFailedAttempts = () => {
        setLoadingFailedAttemptsData(true);
        GetRequest(getBackendLink() + `/api/v1/security/failed_attempts?page=${failedAttemptsTableParams.pagination.current}&size=${failedAttemptsTableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setFailedAttemptsErr(result.error)
                    } else {
                        setFailedAttemptsErr("")
                        setFailedAttemptsData(result.result.Records);
                        setFailedAttemptsTableParams({
                            ...failedAttemptsTableParams,
                            pagination: {
                                ...failedAttemptsTableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoadingFailedAttemptsData(false);
                },
                (error) => {
                    setLoadingFailedAttemptsData(false);
                    NotificationError('Error', error.message)
                }
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchFailedAttempts();
        // eslint-disable-next-line
    }, [JSON.stringify(failedAttemptsTableParams)]);

    const columnsFailedAttempts = [
        {
            title: 'Time',
            dataIndex: 'Time',
            key: 'Time',
            render: (_, record) => (
                <>{record.createdAt ?
                    <TimeFromSeconds seconds={record.createdAt.seconds} />
                    : <></>}</>
            ),
        },
        {
            title: 'IP',
            dataIndex: 'ipAddr',
            key: 'ipAddr',
            render: (_, record) => (
                <>{record.ipAddr !== "" && record.ipAddr !== undefined ? GetIPCheckerLink(record.ipAddr) : <></>}</>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            render: (_, record) => (
                <>{record.Country !== "" && record.Country !== undefined ? record.Country : <>unknown</>}{record.Location !== "" && record.Location !== undefined ? <>, {record.Location}</> : <>, unknown</>}</>
            ),
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'method',
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'User',
            dataIndex: 'User',
            key: 'User',
            render: (_, record) => (
                <>{record.userID !== "" ?
                    <a href={GetUserLink(record.userID)}>{record.userName}</a> : record.userID}</>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Failed logins list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminUsersFailedLogins />
                    </Col>
                </Row>
                <ErrorRow err={failedAttemptsErr} />
                <Row>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columnsFailedAttempts}
                            rowKey={(record) => record.ID}
                            dataSource={failedAttemptsData}
                            pagination={failedAttemptsTableParams.pagination}
                            loading={loadingFailedAttemptsData}
                            size={"small"}
                            onChange={handleFailedAttemptsTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )


}