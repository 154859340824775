import React, { useState, useEffect } from "react";
import { Col, Row, Tag, Card, Typography } from "antd";
import { Helmet } from "react-helmet";
import {
    BarChartOutlined,
    BarcodeOutlined,
    BellOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    TeamOutlined
} from "@ant-design/icons";
import ReactTimeAgo from 'react-time-ago';
import JSONPretty from 'react-json-pretty';
import { ErrorRow } from "../../components/errorBlock";
import { BreadcrumbsDeveloper } from "../../components/breadcrumbs/breadcrumbsDeveloper";
import { Spinner } from "../../components/spinners";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { GetApplicationRunLink, GetServiceLink } from "../../components/getPageLinks";
import {
    LogSourceAuthService,
    LogSourceLoggerService,
    LogSourceNotifyService,
    LogSourceProductsService,
    LogSourceEcosystemService
} from "../../components/backend/services";

const { Title, Text } = Typography;

export const DeveloperMain = () => {
    return (
        <>
            <Helmet>
                <title>Developer area || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloper />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row gutter={[16, 16]}>
                    <Col xs={24} xl={8}>
                        <GetServiceStats
                            name={<><TeamOutlined /> Auth service</>}
                            url={"/api/v1/dev/services/status/auth"}
                            serviceLink={GetServiceLink(LogSourceAuthService)}
                        />
                    </Col>
                    <Col xs={24} xl={8}>
                        <GetServiceStats
                            name={<><BarChartOutlined /> Logger service</>}
                            url={"/api/v1/dev/services/status/logger"}
                            serviceLink={GetServiceLink(LogSourceLoggerService)}
                        />
                    </Col>
                    <Col xs={24} xl={8}>
                        <GetServiceStats
                            name={<><BellOutlined /> Notify service</>}
                            url={"/api/v1/dev/services/status/notifications"}
                            serviceLink={GetServiceLink(LogSourceNotifyService)}
                        />
                    </Col>
                    <Col xs={24} xl={8}>
                        <GetServiceStats
                            name={<><BarcodeOutlined /> Product service</>}
                            url={"/api/v1/dev/services/status/products"}
                            serviceLink={GetServiceLink(LogSourceProductsService)}
                        />
                    </Col>
                    <Col xs={24} xl={8}>
                        <GetServiceStats
                            name={<><BarcodeOutlined /> Ecosystem service</>}
                            url={"/api/v1/dev/services/status/ecosystem"}
                            serviceLink={GetServiceLink(LogSourceEcosystemService)}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

const GetServiceStats = ({ name, url, serviceLink }) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState({ StartedAt: { seconds: 0 } });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = () => {
            GetRequestInternalAPI(url, setLoading, setErr, null, false)
                .then((result = { status: {} }) => {
                    if (result.status !== null) {
                        setData(result.status);
                    }
                });
        };

        getData();
    }, [url]);

    if (err) {
        return (
            <Card>
                <Title level={4}>{name}</Title>
                <Text strong>Status:</Text> <Tag icon={<CloseCircleOutlined />} color="error">offline</Tag>
                <ErrorRow err={err} prefix={"Stats"} />
            </Card>
        );
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <Card>
            <Title level={5}>
                {name}{" "}
                <a href={serviceLink} target="_blank" rel="noopener noreferrer">
                    <InfoCircleOutlined style={{ marginRight: 8 }} />
                    Service Info
                </a>
            </Title>
            <Text strong>Status:</Text> <Tag icon={<CheckCircleOutlined />} color="success">online</Tag><br />
            <Text strong>Started At:</Text>{" "}
            <Text>
                <TimeFromSeconds seconds={data.StartedAt.seconds} />{" "}
                (<ReactTimeAgo date={data.StartedAt.seconds * 1000} locale="en-US" />)
            </Text><br />
            <Text strong>Run ID:</Text> <Text>{GetApplicationRunLink(data.RunID)}</Text><br />
            <Text strong>Environment:</Text>{" "}
            <Text>
                <JSONPretty id="json-pretty" data={data.RunMeta} />
            </Text>
        </Card>
    );
};

