import React from 'react';
import { Avatar, Typography } from "antd";
import { GetLinkProductAskas } from "./askas";
import { FormatShortUUID } from "./formattingValues";
const { Paragraph } = Typography

export function GetAppLink(app_id = 0) {
    if (app_id === 1) {
        return "/developer/slim4"
    }
    if (app_id === 2) {
        return "/developer/moreflo"
    }
    if (app_id === 3) {
        return "/developer/products/replicator/v1"
    }
    if (app_id === 4) {
        return "/developer/products/feeds/v2"
    }

    if (app_id === 5) { // It's the Admin app itself.
        return "/"
    }

    if (app_id === 6) { // Gamifiera user feed - has no link for now.
        return "/"
    }

    return ""
}

export function GetServiceLink(service_id = "") {
    return "/developer/services/" + service_id
}

export function GetUserLink(user_id = '') {
    return `/profile/${user_id}`
}

export function GetUserLinkElementAvatar(props) {
    return <a target={"_blank"} rel={"noreferrer"} href={GetUserLink(props.id)}><Avatar src={props.avatar} /></a>
}

export function GetUserLinkElement(props) {
    return <a target={"_blank"} rel={"noreferrer"} href={GetUserLink(props.id)}>{props.name}</a>
}

export function GetUserLinkTag(id = '', name = '') {
    return <Paragraph><a href={`/profile/${id}`} target={"_blank"} rel={"noreferrer"}>{name}</a></Paragraph>
}

export function GetProductLinkTag(sku = '') {
    return <Paragraph><a href={`/products/${sku}`} target={"_blank"} rel={"noreferrer"}>{sku}</a></Paragraph>
}
export function GetProductLinkTagCopyable(sku = '') {
    if (sku === "") {
        return <></>
    }

    return <Paragraph copyable={{ text: sku }}><a href={`/products/${sku}`} target={"_blank"} rel={"noreferrer"}>{sku}</a></Paragraph>
}

export function GetProductLinkAskas(sku = '') {
    return <a href={GetLinkProductAskas(sku)} target={"_blank"} rel={"noreferrer"}>{sku}</a>
}

export function GetProductHrefRedis(sku = '') {
    return `/products/${sku}/redis`
}

export function GetProductHref(sku = '') {
    return `/products/${sku}`
}

export function GetProductHrefMoreFlo(sku = '') {
    return `/products/${sku}/more_flo`
}

export function GetEventHref(eventID = "") {
    return `/stat/events/${eventID}`
}

export function GetEventLink(eventID = "", text = "") {
    if (eventID === "" || eventID === undefined) {
        return <></>
    }

    if (text === "" || text === undefined) {
        text = eventID
    }

    return <a href={GetEventHref(eventID)} target={"_blank"} rel={"noreferrer"}>{text}</a>
}

export function GetApplicationRunHref(runID = "") {
    return `/developer/services/runs/${runID}`
}

export function GetApplicationRunLink(runID = "", text = "") {
    if (runID === "" || runID === undefined) {
        return <></>
    }

    if (text === "" || text === undefined) {
        text = runID
    }

    return <a href={GetApplicationRunHref(runID)} target={"_blank"} rel={"noreferrer"}>{FormatShortUUID(text)}</a>
}

export function GetCampaignHref(campaignID = "") {
    return `/campaigns/v2/${campaignID}`
}

export function GetCampaignLink(campaignID = "", text = "") {
    if (campaignID === "" || campaignID === undefined) {
        return <></>
    }

    if (text === "" || text === undefined) {
        text = campaignID
    }

    return <a href={GetCampaignHref(campaignID)} target={"_blank"} rel={"noreferrer"}>{FormatShortUUID(text)}</a>
}

export function GetIPCheckerHref(ip = "") {
    return `https://proxycheck.io/v2/${ip}?vpn=1&asn=1`
}

export function GetIPCheckerLink(ip = "") {
    if (ip === "" || ip === undefined) {
        return <></>
    }

    return <a href={GetIPCheckerHref(ip)} target={"_blank"} rel={"noreferrer"}>{ip}</a>
}

export function GetClickUpTaskLink(id = "", name = "") {
    if (name === "" || name === undefined) {
        name = id
    }

    return <a href={`https://app.clickup.com/t/${id}`} target='_blank' rel={"noreferrer"}>{name}</a>
}